module.exports = [{
      plugin: require('../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"className":"header-link-icon","offsetY":0},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Riverscapes Gatsby Template Site","short_name":"RiverscapesTemplate","start_url":"/","background_color":"#ffffff","display":"minimal-ui","icon":"./static/qris-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"62a653f10221db65a34a44fa9b66e248"},
    },{
      plugin: require('../node_modules/@riverscapes/gatsby-theme/gatsby-browser.js'),
      options: {"plugins":[],"contentPath":"/home/runner/work/QRiS/QRiS/docs/content/page","manifest":{"name":"Riverscapes Gatsby Template Site","short_name":"RiverscapesTemplate","start_url":"/","iconUrl":"./static/qris-icon.png"}},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
